import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { styled } from '@moonpig/launchpad-utils'
import { system } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import {
  IconSystemChevronDown,
  IconSystemChevronUp,
} from '@moonpig/launchpad-assets'
import React, { FC, PropsWithChildren, useState } from 'react'

const DropdownMenuContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof RadixDropdownMenu.Content>
>(({ children, ...props }, forwardedRef) => {
  return (
    <RadixDropdownMenu.Content {...props} ref={forwardedRef}>
      {children}
      <StyledDropdownMenuArrow />
    </RadixDropdownMenu.Content>
  )
})

const DropdownMenuItem = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof RadixDropdownMenu.Item>
>(({ children, ...props }, forwardedRef) => {
  return (
    <RadixDropdownMenu.Item {...props} ref={forwardedRef}>
      {children}
    </RadixDropdownMenu.Item>
  )
})

const DropdownMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof RadixDropdownMenu.Trigger>
>(({ children, ...props }, forwardedRef) => {
  return (
    <RadixDropdownMenu.Trigger {...props} ref={forwardedRef}>
      {children}
    </RadixDropdownMenu.Trigger>
  )
})

const StyledDropdownMenuRoot = styled(({ ...props }) => (
  <RadixDropdownMenu.Root {...props} />
))``

const StyledDropdownMenuTrigger = styled(DropdownMenuTrigger)`
  cursor: pointer;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }

  &:focus:not(:focus-visible) {
    outline: none !important;
    box-shadow: none !important;
  }
`

const StyledDropdownMenuItem = styled(({ ...props }) => (
  <DropdownMenuItem {...props} />
))`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  position: relative;
  outline: none;
  cursor: pointer;
  user-select: none;
  color: ${colorValue('colorPrimary01')};
  font-weight: 400;
  font-size: 14px;

  opacity: ${props => (props.disabled ? '.3' : '1')};

  &[data-highlighted] {
    background-color: ${colorValue('colorBackgroundInformation')};
    color: ${colorValue('colorInteractionTextLinkHover')};
    outline: none;
    text-decoration: underline;
  }
`

const StyledDropdownMenuSeparator = styled(({ ...props }) => (
  <RadixDropdownMenu.Separator {...props} />
))`
  height: 1px;
  background-color: #e2e3e6;
  margin: 5px;
`

const StyledDropdownMenuArrow = styled(({ ...props }) => (
  <RadixDropdownMenu.Arrow {...props} />
))`
  fill: white;
`

const StyledDropdownMenuContent = styled(({ ...props }) => (
  <DropdownMenuContent {...props} />
))`
  min-width: 220px;
  background-color: white;
  z-index: 100;

  ${system({ boxShadow: 2 })}
`

type DropdownProps = {
  trigger?: ({ isExpanded }: { isExpanded: boolean }) => React.ReactNode
  triggerRef?: React.RefObject<HTMLButtonElement>
  hiddenByDefault?: boolean
  'data-testid'?: string
  onOpenChange?: (open: boolean) => void
}

const DropdownMenu: FC<PropsWithChildren<DropdownProps>> = props => {
  const triggerRef = React.createRef<HTMLButtonElement>()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  return (
    <StyledDropdownMenuRoot
      data-testid={`${props['data-testid']}-context-menu-container`}
      onOpenChange={(open: boolean) => {
        setIsExpanded(open)

        props.onOpenChange && props.onOpenChange(open)
      }}
      modal={false}
    >
      <StyledDropdownMenuTrigger
        data-testid={`${props['data-testid']}-context-menu-trigger`}
        asChild
        ref={props.triggerRef ? props.triggerRef : triggerRef}
        style={{ display: props.hiddenByDefault ? 'none' : 'block' }}
      >
        {props.trigger ? (
          props.trigger({ isExpanded })
        ) : isExpanded ? (
          <IconSystemChevronUp width={'16px'} height={'16px'} />
        ) : (
          <IconSystemChevronDown width={'16px'} height={'16px'} />
        )}
      </StyledDropdownMenuTrigger>

      <RadixDropdownMenu.Portal>
        {isExpanded && (
          <StyledDropdownMenuContent
            align="start"
            data-testid={`${props['data-testid']}-context-menu-items`}
          >
            {props.children}
          </StyledDropdownMenuContent>
        )}
      </RadixDropdownMenu.Portal>
    </StyledDropdownMenuRoot>
  )
}

export {
  DropdownMenu,
  StyledDropdownMenuTrigger as DropdownMenuTrigger,
  StyledDropdownMenuItem as DropdownMenuItem,
  StyledDropdownMenuSeparator as DropdownMenuSeparator,
}
