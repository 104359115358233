export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  StudioDegrees: { input: number; output: number }
  StudioMillimetres: { input: number; output: number }
  StudioPixels: { input: number; output: number }
  StudioPoints: { input: number; output: number }
}

export enum ImportStatus {
  COMPLETE = 'COMPLETE',
  CREATED = 'CREATED',
  EXTRACTING = 'EXTRACTING',
  FAILED = 'FAILED',
}

export type Mutation = {
  __typename: 'Mutation'
  createTemplate: StudioCreateTemplateResult
  createTemplateGroup: StudioCreateTemplateGroupResult
  createTemplateImport: StudioCreateTemplateImportResult
  deleteGroup: StudioDeleteGroupResult
  deleteTemplate: StudioDeleteTemplateResult
  duplicateTemplate: StudioTemplateDuplicateResult
  moveGroup: StudioMovedGroupResult
  moveTemplate: StudioMoveTemplateResult
  renameGroup: StudioRenamedGroupResult
  renameTemplate: StudioRenameTemplateResult
  updateTemplate: StudioUpdateTemplateResult
  uploadFont: StudioUploadFontResult
}

export type MutationCreateTemplateArgs = {
  input: StudioCreateTemplateRequest
}

export type MutationCreateTemplateGroupArgs = {
  input: StudioCreateTemplateGroupRequest
}

export type MutationCreateTemplateImportArgs = {
  createdBy: Scalars['String']['input']
  groupPath: Scalars['String']['input']
  templateName: Scalars['String']['input']
}

export type MutationDeleteGroupArgs = {
  input: StudioDeleteGroupRequest
}

export type MutationDeleteTemplateArgs = {
  input: StudioDeleteTemplateRequest
}

export type MutationDuplicateTemplateArgs = {
  input: StudioDuplicateTemplateRequest
}

export type MutationMoveGroupArgs = {
  input: StudioMoveGroupInput
}

export type MutationMoveTemplateArgs = {
  input: StudioMoveTemplateRequest
}

export type MutationRenameGroupArgs = {
  input: StudioRenameGroupInput
}

export type MutationRenameTemplateArgs = {
  input: StudioRenameTemplateRequest
}

export type MutationUpdateTemplateArgs = {
  input: StudioUpdateTemplateRequest
}

export type MutationUploadFontArgs = {
  fontName: Scalars['String']['input']
  uploadedBy: Scalars['String']['input']
}

export type Query = {
  __typename: 'Query'
  searchByName: StudioSearchResult
  searchByPath?: Maybe<StudioSearchPathResult>
  studioFeatures?: Maybe<StudioFeaturesResult>
  studioFonts?: Maybe<Array<Maybe<StudioFontResult>>>
  studioGroupByPath: StudioGroupByPathResult
  studioGroupsByPathSubstring?: Maybe<Array<Maybe<StudioGroupSearchResult>>>
  studioIntents: Array<StudioIntent>
  studioNodeMetaData: StudioNodeMetaDataResult
  studioRootGroups: StudioRootGroupResult
  studioTemplateById: StudioTemplateResult
  studioTemplateGroupsByIds?: Maybe<Array<Maybe<StudioGroupTemplate>>>
  templateImportStatusById: StudioTemplateImportStatusResult
}

export type QuerySearchByNameArgs = {
  name: Scalars['String']['input']
}

export type QuerySearchByPathArgs = {
  term: Scalars['String']['input']
}

export type QueryStudioGroupByPathArgs = {
  path: Scalars['String']['input']
  sortBy?: InputMaybe<StudioSortBy>
}

export type QueryStudioGroupsByPathSubstringArgs = {
  path: Scalars['String']['input']
}

export type QueryStudioNodeMetaDataArgs = {
  input: StudioNodeMetaDataInput
}

export type QueryStudioTemplateByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryStudioTemplateGroupsByIdsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type QueryTemplateImportStatusByIdArgs = {
  templateId: Scalars['String']['input']
}

export type StudioAsset = {
  __typename: 'StudioAsset'
  contentType: Scalars['String']['output']
  key: Scalars['String']['output']
  status: StudioAssetStatus
  url: Scalars['String']['output']
}

export type StudioAssetInput = {
  contentType: Scalars['String']['input']
  key?: InputMaybe<Scalars['String']['input']>
  status: StudioAssetStatus
}

export enum StudioAssetStatus {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export type StudioColor = {
  __typename: 'StudioColor'
  c?: Maybe<Scalars['Int']['output']>
  hex: Scalars['String']['output']
  k?: Maybe<Scalars['Int']['output']>
  m?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  y?: Maybe<Scalars['Int']['output']>
}

export type StudioColorInput = {
  hex: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type StudioCreateTemplateGroupError = {
  __typename: 'StudioCreateTemplateGroupError'
  message: Scalars['String']['output']
}

export type StudioCreateTemplateGroupInput = {
  name: Scalars['String']['input']
  parentPath: Scalars['String']['input']
  path: Scalars['String']['input']
}

export type StudioCreateTemplateGroupRequest = {
  group: StudioCreateTemplateGroupInput
}

export type StudioCreateTemplateGroupResult =
  | StudioCreateTemplateGroupError
  | StudioCreatedTemplateGroup
  | StudioDuplicatePathError

export type StudioCreateTemplateImportResult =
  | StudioDuplicateNameError
  | StudioDuplicatePathError
  | StudioImportError
  | StudioTemplateCreateImport

export type StudioCreateTemplateInput = {
  availableFontSizes: Array<Scalars['Float']['input']>
  availableFonts: Array<StudioFontInput>
  colors: Array<StudioColorInput>
  displayName: Scalars['String']['input']
  height: Scalars['Float']['input']
  imageElements: Array<StudioImageElementInput>
  orientation: StudioOrientation
  placeholderTextElements: Array<StudioPlaceholderTextElementInput>
  plainTextElements: Array<StudioPlainTextElementInput>
  rectangleElements: Array<StudioRectangleElementInput>
  renderMethod?: InputMaybe<StudioRenderMethod>
  staticTextElements: Array<StudioStaticTextElementInput>
  styledTextElements: Array<StudioStyledTextElementInput>
  width: Scalars['Float']['input']
  xBleed?: InputMaybe<Scalars['Float']['input']>
  yBleed?: InputMaybe<Scalars['Float']['input']>
}

export type StudioCreateTemplateRequest = {
  createdBy: Scalars['String']['input']
  groupPath: Scalars['String']['input']
  template: StudioCreateTemplateInput
}

export type StudioCreateTemplateResult =
  | StudioCreatedTemplate
  | StudioCreatedTemplateError
  | StudioDuplicateNameError
  | StudioTemplateValidationErrorResult

export type StudioCreatedTemplate = {
  __typename: 'StudioCreatedTemplate'
  id: Scalars['String']['output']
  signedImageUrls: Array<StudioElementSignedUrl>
  signedMaskUrls: Array<StudioElementSignedUrl>
}

export type StudioCreatedTemplateError = {
  __typename: 'StudioCreatedTemplateError'
  message: Scalars['String']['output']
}

export type StudioCreatedTemplateGroup = {
  __typename: 'StudioCreatedTemplateGroup'
  id: Scalars['String']['output']
}

export type StudioDeleteGroupError = {
  __typename: 'StudioDeleteGroupError'
  message: Scalars['String']['output']
}

export type StudioDeleteGroupInput = {
  id: Scalars['ID']['input']
}

export type StudioDeleteGroupRequest = {
  group: StudioDeleteGroupInput
}

export type StudioDeleteGroupResult =
  | StudioDeleteGroupError
  | StudioDeleteNonEmptyGroupError
  | StudioDeletedGroup

export type StudioDeleteNonEmptyGroupError = {
  __typename: 'StudioDeleteNonEmptyGroupError'
  message: Scalars['String']['output']
}

export type StudioDeleteTemplateError = {
  __typename: 'StudioDeleteTemplateError'
  message: Scalars['String']['output']
}

export type StudioDeleteTemplateInput = {
  id: Scalars['ID']['input']
}

export type StudioDeleteTemplateRequest = {
  template: StudioDeleteTemplateInput
}

export type StudioDeleteTemplateResult =
  | StudioDeleteTemplateError
  | StudioDeletedTemplate
  | StudioTemplateIsMappedError

export type StudioDeletedGroup = {
  __typename: 'StudioDeletedGroup'
  id: Scalars['String']['output']
}

export type StudioDeletedTemplate = {
  __typename: 'StudioDeletedTemplate'
  id: Scalars['String']['output']
}

export type StudioDuplicateNameError = {
  __typename: 'StudioDuplicateNameError'
  name: Scalars['String']['output']
}

export type StudioDuplicatePathError = {
  __typename: 'StudioDuplicatePathError'
  path: Scalars['String']['output']
}

export type StudioDuplicateTemplateError = {
  __typename: 'StudioDuplicateTemplateError'
  message: Scalars['String']['output']
}

export type StudioDuplicateTemplateRequest = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  parentPath: Scalars['String']['input']
}

export type StudioDuplicatedTemplate = {
  __typename: 'StudioDuplicatedTemplate'
  path: Scalars['String']['output']
}

export type StudioElementSignedUrl = {
  __typename: 'StudioElementSignedUrl'
  elementIndex: Scalars['Int']['output']
  url: Scalars['String']['output']
}

export type StudioError = {
  __typename: 'StudioError'
  message: Scalars['String']['output']
}

export type StudioFallbackFont = {
  __typename: 'StudioFallbackFont'
  displayName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastUpdated: Scalars['String']['output']
  originalFont: Scalars['String']['output']
  urls: StudioFontUrls
}

export type StudioFeature = {
  __typename: 'StudioFeature'
  enabled: Scalars['Boolean']['output']
  key: Scalars['String']['output']
}

export type StudioFeaturesResult = {
  __typename: 'StudioFeaturesResult'
  features: Array<StudioFeature>
}

export type StudioFont = {
  __typename: 'StudioFont'
  displayName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastUpdated: Scalars['String']['output']
  urls: StudioFontUrls
}

export type StudioFontError = {
  __typename: 'StudioFontError'
  message: Scalars['String']['output']
}

export type StudioFontInput = {
  id: Scalars['ID']['input']
}

export type StudioFontOrFallbackFont = StudioFallbackFont | StudioFont

export type StudioFontResult = StudioFallbackFont | StudioFont | StudioFontError

export type StudioFontUpload = {
  __typename: 'StudioFontUpload'
  fontName: Scalars['String']['output']
  signedUrl: Scalars['String']['output']
}

export type StudioFontUploadError = {
  __typename: 'StudioFontUploadError'
  message: Scalars['String']['output']
}

export type StudioFontUrls = {
  __typename: 'StudioFontUrls'
  source?: Maybe<Scalars['String']['output']>
  ttf?: Maybe<Scalars['String']['output']>
  woff?: Maybe<Scalars['String']['output']>
  woff2?: Maybe<Scalars['String']['output']>
}

export type StudioGroup = {
  __typename: 'StudioGroup'
  children: Array<StudioNode>
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  parentPath: Scalars['String']['output']
  path: Scalars['String']['output']
}

export type StudioGroupByPathResult = StudioGroup | StudioGroupError

export type StudioGroupError = {
  __typename: 'StudioGroupError'
  message: Scalars['String']['output']
}

export type StudioGroupSearch = {
  __typename: 'StudioGroupSearch'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  parentPath: Scalars['String']['output']
  path: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type StudioGroupSearchResult = StudioGroupError | StudioGroupSearch

export type StudioGroupTemplate = {
  __typename: 'StudioGroupTemplate'
  id: Scalars['String']['output']
  lastUpdated?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  parentPath: Scalars['String']['output']
  path: Scalars['String']['output']
  previewStatus?: Maybe<StudioPreviewStatus>
  previewUrl: Scalars['String']['output']
  templateStatus?: Maybe<Scalars['String']['output']>
  updatedBy?: Maybe<Scalars['String']['output']>
}

export enum StudioHorizontalAlignment {
  CENTER = 'CENTER',
  JUSTIFY = 'JUSTIFY',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type StudioImageElement = {
  __typename: 'StudioImageElement'
  editable: Scalars['Boolean']['output']
  height: Scalars['StudioMillimetres']['output']
  image: StudioAsset
  isHandwriting?: Maybe<Scalars['Boolean']['output']>
  mask?: Maybe<StudioAsset>
  name: Scalars['String']['output']
  opacity: Scalars['Float']['output']
  required: Scalars['Boolean']['output']
  rotation: Scalars['StudioDegrees']['output']
  visible?: Maybe<Scalars['Boolean']['output']>
  width: Scalars['StudioMillimetres']['output']
  x: Scalars['StudioMillimetres']['output']
  y: Scalars['StudioMillimetres']['output']
}

export type StudioImageElementHeightArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioImageElementRotationArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioImageElementWidthArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioImageElementXArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioImageElementYArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioImageElementInput = {
  editable: Scalars['Boolean']['input']
  height: Scalars['Float']['input']
  image: StudioAssetInput
  index: Scalars['Int']['input']
  isHandwriting?: InputMaybe<Scalars['Boolean']['input']>
  mask?: InputMaybe<StudioAssetInput>
  name: Scalars['String']['input']
  opacity: Scalars['Float']['input']
  required: Scalars['Boolean']['input']
  rotation: Scalars['Float']['input']
  visible?: InputMaybe<Scalars['Boolean']['input']>
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type StudioImportError = {
  __typename: 'StudioImportError'
  message: Scalars['String']['output']
}

export type StudioIntent = {
  __typename: 'StudioIntent'
  disabled?: Maybe<Scalars['Boolean']['output']>
  formats?: Maybe<Array<StudioIntentFormats>>
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type StudioIntentFormats = {
  __typename: 'StudioIntentFormats'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type StudioInvalidNameError = {
  __typename: 'StudioInvalidNameError'
  name: Scalars['String']['output']
}

export type StudioKeyValuePair = {
  __typename: 'StudioKeyValuePair'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type StudioMoveGroupFailed = {
  __typename: 'StudioMoveGroupFailed'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type StudioMoveGroupInput = {
  groupId: Scalars['ID']['input']
  path: Scalars['String']['input']
}

export type StudioMoveTemplateError = {
  __typename: 'StudioMoveTemplateError'
  message: Scalars['String']['output']
}

export type StudioMoveTemplateRequest = {
  id: Scalars['ID']['input']
  path: Scalars['String']['input']
  version: Scalars['String']['input']
}

export type StudioMoveTemplateResult =
  | StudioDuplicatePathError
  | StudioMoveTemplateError
  | StudioMovedTemplate

export type StudioMovedGroup = {
  __typename: 'StudioMovedGroup'
  path: Scalars['String']['output']
}

export type StudioMovedGroupResult = StudioMoveGroupFailed | StudioMovedGroup

export type StudioMovedTemplate = {
  __typename: 'StudioMovedTemplate'
  id: Scalars['String']['output']
  path: Scalars['String']['output']
}

export type StudioNode = StudioGroup | StudioGroupTemplate

export type StudioNodeMetaData = {
  __typename: 'StudioNodeMetaData'
  actions?: Maybe<StudioNodeMetaDataActions>
  id: Scalars['String']['output']
  path: Scalars['String']['output']
  totals?: Maybe<StudioNodeMetaDataTotals>
  type: Scalars['String']['output']
}

export type StudioNodeMetaDataActions = {
  __typename: 'StudioNodeMetaDataActions'
  canDeleteNode?: Maybe<Scalars['Boolean']['output']>
}

export type StudioNodeMetaDataInput = {
  path: Scalars['String']['input']
}

export type StudioNodeMetaDataResult = StudioError | StudioNodeMetaData

export type StudioNodeMetaDataTotals = {
  __typename: 'StudioNodeMetaDataTotals'
  groups?: Maybe<Scalars['Int']['output']>
  templates?: Maybe<Scalars['Int']['output']>
}

export type StudioNotFound = {
  __typename: 'StudioNotFound'
  message: Scalars['String']['output']
}

export enum StudioOrientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
}

export type StudioPlaceholderTemplateTextPart =
  | StudioPlainTextPart
  | StudioStaticTextPart

export type StudioPlaceholderTextElementInput = {
  color: StudioColorInput
  font: StudioFontInput
  fontSize: Scalars['Float']['input']
  height: Scalars['Float']['input']
  horizontalAlignment: StudioHorizontalAlignment
  index: Scalars['Int']['input']
  isHandwriting?: InputMaybe<Scalars['Boolean']['input']>
  lineSpacing: Scalars['Float']['input']
  name: Scalars['String']['input']
  opacity: Scalars['Float']['input']
  plainTextParts: Array<StudioPlainTextPartInput>
  rotation: Scalars['Float']['input']
  shadow?: InputMaybe<StudioTextShadowInput>
  staticTextParts: Array<StudioStaticTextPartInput>
  verticalAlignment: StudioVerticalAlignment
  visible?: InputMaybe<Scalars['Boolean']['input']>
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type StudioPlaceholderTextPart = {
  __typename: 'StudioPlaceholderTextPart'
  textParts: Array<StudioPlaceholderTemplateTextPart>
}

export type StudioPlainTextElementInput = {
  allowBlank: Scalars['Boolean']['input']
  allowDefault: Scalars['Boolean']['input']
  color: StudioColorInput
  customNo: Scalars['Int']['input']
  font: StudioFontInput
  fontSize: Scalars['Float']['input']
  height: Scalars['Float']['input']
  horizontalAlignment: StudioHorizontalAlignment
  index: Scalars['Int']['input']
  isHandwriting?: InputMaybe<Scalars['Boolean']['input']>
  lineSpacing: Scalars['Float']['input']
  maxCharacters?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  opacity: Scalars['Float']['input']
  rotation: Scalars['Float']['input']
  shadow?: InputMaybe<StudioTextShadowInput>
  text: Scalars['String']['input']
  textIntent?: InputMaybe<Array<StudioTextIntentInput>>
  textTransform?: InputMaybe<StudioTextTransform>
  verticalAlignment: StudioVerticalAlignment
  visible?: InputMaybe<Scalars['Boolean']['input']>
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type StudioPlainTextPart = {
  __typename: 'StudioPlainTextPart'
  allowBlank: Scalars['Boolean']['output']
  allowDefault: Scalars['Boolean']['output']
  customNo: Scalars['Int']['output']
  maxCharacters?: Maybe<Scalars['Int']['output']>
  text: Scalars['String']['output']
  textIntent?: Maybe<Array<StudioTextIntent>>
  textTransform?: Maybe<StudioTextTransform>
}

export type StudioPlainTextPartInput = {
  allowBlank: Scalars['Boolean']['input']
  allowDefault: Scalars['Boolean']['input']
  customNo: Scalars['Int']['input']
  index: Scalars['Int']['input']
  maxCharacters?: InputMaybe<Scalars['Int']['input']>
  text: Scalars['String']['input']
  textIntent?: InputMaybe<Array<StudioTextIntentInput>>
  textTransform?: InputMaybe<StudioTextTransform>
}

export enum StudioPreviewStatus {
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
}

export type StudioRectangleElement = {
  __typename: 'StudioRectangleElement'
  fill: StudioRectangleFill
  height: Scalars['StudioMillimetres']['output']
  name: Scalars['String']['output']
  opacity: Scalars['Float']['output']
  outline: StudioRectangleOutline
  rotation: Scalars['StudioDegrees']['output']
  visible?: Maybe<Scalars['Boolean']['output']>
  width: Scalars['StudioMillimetres']['output']
  x: Scalars['StudioMillimetres']['output']
  y: Scalars['StudioMillimetres']['output']
}

export type StudioRectangleElementHeightArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioRectangleElementRotationArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioRectangleElementWidthArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioRectangleElementXArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioRectangleElementYArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioRectangleElementInput = {
  fill: StudioRectangleFillInput
  height: Scalars['Float']['input']
  index: Scalars['Int']['input']
  name: Scalars['String']['input']
  opacity: Scalars['Float']['input']
  outline: StudioRectangleOutlineInput
  rotation: Scalars['Float']['input']
  visible?: InputMaybe<Scalars['Boolean']['input']>
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type StudioRectangleFill = {
  __typename: 'StudioRectangleFill'
  color: StudioColor
}

export type StudioRectangleFillInput = {
  color: StudioColorInput
}

export type StudioRectangleOutline = {
  __typename: 'StudioRectangleOutline'
  color: StudioColor
  width: Scalars['Float']['output']
}

export type StudioRectangleOutlineInput = {
  color: StudioColorInput
  width: Scalars['Float']['input']
}

export type StudioRenameGroupFailed = {
  __typename: 'StudioRenameGroupFailed'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type StudioRenameGroupInput = {
  groupId: Scalars['ID']['input']
  name: Scalars['String']['input']
}

export type StudioRenameTemplateError = {
  __typename: 'StudioRenameTemplateError'
  message: Scalars['String']['output']
}

export type StudioRenameTemplateInput = {
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  version: Scalars['String']['input']
}

export type StudioRenameTemplateRequest = {
  template: StudioRenameTemplateInput
}

export type StudioRenameTemplateResult =
  | StudioDuplicateNameError
  | StudioDuplicatePathError
  | StudioInvalidNameError
  | StudioRenameTemplateError
  | StudioRenamedTemplate

export type StudioRenamedGroup = {
  __typename: 'StudioRenamedGroup'
  id: Scalars['ID']['output']
}

export type StudioRenamedGroupResult =
  | StudioRenameGroupFailed
  | StudioRenamedGroup

export type StudioRenamedTemplate = {
  __typename: 'StudioRenamedTemplate'
  id: Scalars['String']['output']
  name: Scalars['String']['output']
  path: Scalars['String']['output']
}

export enum StudioRenderMethod {
  GDI = 'GDI',
  WPF = 'WPF',
}

export type StudioRootGroupResult = StudioGroup | StudioGroupError

export type StudioSearchPathResult =
  | StudioError
  | StudioGroup
  | StudioGroupTemplate
  | StudioNotFound

export type StudioSearchResult = StudioGroup | StudioGroupError

export enum StudioSortBy {
  GROUP = 'GROUP',
  TEMPLATE_MAPPING = 'TEMPLATE_MAPPING',
}

export type StudioStaticTextElementInput = {
  color: StudioColorInput
  font: StudioFontInput
  fontSize: Scalars['Float']['input']
  height: Scalars['Float']['input']
  horizontalAlignment: StudioHorizontalAlignment
  index: Scalars['Int']['input']
  isHandwriting?: InputMaybe<Scalars['Boolean']['input']>
  lineSpacing: Scalars['Float']['input']
  name: Scalars['String']['input']
  opacity: Scalars['Float']['input']
  rotation: Scalars['Float']['input']
  shadow?: InputMaybe<StudioTextShadowInput>
  text: Scalars['String']['input']
  textIntent?: InputMaybe<Array<StudioTextIntentInput>>
  verticalAlignment: StudioVerticalAlignment
  visible?: InputMaybe<Scalars['Boolean']['input']>
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type StudioStaticTextPart = {
  __typename: 'StudioStaticTextPart'
  text: Scalars['String']['output']
  textIntent?: Maybe<Array<StudioTextIntent>>
}

export type StudioStaticTextPartInput = {
  index: Scalars['Int']['input']
  text: Scalars['String']['input']
  textIntent?: InputMaybe<Array<StudioTextIntentInput>>
}

export type StudioStyledTextElementInput = {
  allowBlank: Scalars['Boolean']['input']
  allowDefault: Scalars['Boolean']['input']
  color: StudioColorInput
  font: StudioFontInput
  fontSize: Scalars['Float']['input']
  height: Scalars['Float']['input']
  horizontalAlignment: StudioHorizontalAlignment
  index: Scalars['Int']['input']
  isHandwriting?: InputMaybe<Scalars['Boolean']['input']>
  lineSpacing: Scalars['Float']['input']
  name: Scalars['String']['input']
  opacity: Scalars['Float']['input']
  rotation: Scalars['Float']['input']
  shadow?: InputMaybe<StudioTextShadowInput>
  text: Scalars['String']['input']
  textIntent?: InputMaybe<Array<StudioTextIntentInput>>
  verticalAlignment: StudioVerticalAlignment
  visible?: InputMaybe<Scalars['Boolean']['input']>
  width: Scalars['Float']['input']
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
}

export type StudioStyledTextPart = {
  __typename: 'StudioStyledTextPart'
  allowBlank: Scalars['Boolean']['output']
  allowDefault: Scalars['Boolean']['output']
  text: Scalars['String']['output']
  textIntent?: Maybe<Array<StudioTextIntent>>
}

export type StudioTemplate = {
  __typename: 'StudioTemplate'
  availableFontSizes: Array<Scalars['StudioPoints']['output']>
  availableFonts: Array<StudioFont>
  colors: Array<StudioColor>
  displayName: Scalars['String']['output']
  elements: Array<StudioTemplateElement>
  groupMapping: StudioGroupTemplate
  height: Scalars['StudioMillimetres']['output']
  id: Scalars['ID']['output']
  lastUpdated: Scalars['String']['output']
  orientation: StudioOrientation
  previewStatus?: Maybe<StudioPreviewStatus>
  previewUrl?: Maybe<Scalars['String']['output']>
  renderMethod?: Maybe<StudioRenderMethod>
  status: StudioTemplateStatus
  updatedBy: Scalars['String']['output']
  version: Scalars['String']['output']
  width: Scalars['StudioMillimetres']['output']
  xBleed?: Maybe<Scalars['StudioMillimetres']['output']>
  yBleed?: Maybe<Scalars['StudioMillimetres']['output']>
}

export type StudioTemplateHeightArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioTemplateWidthArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioTemplateCreateImport = {
  __typename: 'StudioTemplateCreateImport'
  signedUrl: Scalars['String']['output']
  templateId: Scalars['String']['output']
  templateName: Scalars['String']['output']
}

export type StudioTemplateDuplicateResult =
  | StudioDuplicateTemplateError
  | StudioDuplicatedTemplate

export type StudioTemplateElement =
  | StudioImageElement
  | StudioRectangleElement
  | StudioTextElement

export type StudioTemplateError = {
  __typename: 'StudioTemplateError'
  message: Scalars['String']['output']
}

export type StudioTemplateGroup = {
  __typename: 'StudioTemplateGroup'
  children?: Maybe<Array<Maybe<StudioTemplateGroup>>>
  displayName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type StudioTemplateImportError = {
  __typename: 'StudioTemplateImportError'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type StudioTemplateImportStatus = {
  __typename: 'StudioTemplateImportStatus'
  created: Scalars['String']['output']
  errors?: Maybe<Array<StudioTemplateImportError>>
  groupPath: Scalars['String']['output']
  status: ImportStatus
  templateId: Scalars['String']['output']
  templateName: Scalars['String']['output']
  updated: Scalars['String']['output']
  updatedBy: Scalars['String']['output']
}

export type StudioTemplateImportStatusResult =
  | StudioImportError
  | StudioTemplateImportStatus

export type StudioTemplateIsMappedError = {
  __typename: 'StudioTemplateIsMappedError'
  message: Scalars['String']['output']
  products: Array<Scalars['String']['output']>
}

export type StudioTemplateResult = StudioTemplate | StudioTemplateError

export enum StudioTemplateStatus {
  IMPORTING = 'IMPORTING',
  MISSING_FONTS = 'MISSING_FONTS',
  READY = 'READY',
  SAVED = 'SAVED',
  SAVING = 'SAVING',
  UNKNOWN = 'UNKNOWN',
}

export type StudioTemplateTextPart =
  | StudioPlaceholderTextPart
  | StudioPlainTextPart
  | StudioStaticTextPart
  | StudioStyledTextPart

export type StudioTemplateValidationError = {
  __typename: 'StudioTemplateValidationError'
  code: Scalars['String']['output']
  elementIndex?: Maybe<Scalars['Int']['output']>
  info: Array<StudioKeyValuePair>
}

export type StudioTemplateValidationErrorResult = {
  __typename: 'StudioTemplateValidationErrorResult'
  errors: Array<StudioTemplateValidationError>
}

export type StudioTextElement = {
  __typename: 'StudioTextElement'
  color: StudioColor
  font: StudioFontOrFallbackFont
  fontSize: Scalars['StudioPoints']['output']
  giftVoucher?: Maybe<Scalars['Boolean']['output']>
  height: Scalars['StudioMillimetres']['output']
  horizontalAlignment: StudioHorizontalAlignment
  isHandwriting?: Maybe<Scalars['Boolean']['output']>
  lineSpacing: Scalars['Float']['output']
  name: Scalars['String']['output']
  opacity: Scalars['Float']['output']
  paragraphSpacing?: Maybe<Scalars['Float']['output']>
  rotation: Scalars['StudioDegrees']['output']
  shadow?: Maybe<StudioTextShadow>
  signature?: Maybe<Scalars['Boolean']['output']>
  text: StudioTemplateTextPart
  userImage?: Maybe<Scalars['Boolean']['output']>
  verticalAlignment: StudioVerticalAlignment
  visible?: Maybe<Scalars['Boolean']['output']>
  width: Scalars['StudioMillimetres']['output']
  x: Scalars['StudioMillimetres']['output']
  y: Scalars['StudioMillimetres']['output']
}

export type StudioTextElementHeightArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioTextElementRotationArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioTextElementWidthArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioTextElementXArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioTextElementYArgs = {
  decimalPlaces?: InputMaybe<Scalars['Int']['input']>
}

export type StudioTextIntent = {
  __typename: 'StudioTextIntent'
  disabled?: Maybe<Scalars['Boolean']['output']>
  format?: Maybe<Scalars['String']['output']>
  intent: Scalars['String']['output']
}

export type StudioTextIntentInput = {
  format?: InputMaybe<Scalars['String']['input']>
  intent: Scalars['String']['input']
}

export type StudioTextShadow = {
  __typename: 'StudioTextShadow'
  color: StudioColor
  offsetX: Scalars['Float']['output']
  offsetY: Scalars['Float']['output']
}

export type StudioTextShadowInput = {
  color: StudioColorInput
  offsetX: Scalars['Float']['input']
  offsetY: Scalars['Float']['input']
}

export enum StudioTextTransform {
  NONE = 'NONE',
  UPPERCASE = 'UPPERCASE',
}

export type StudioUpdateTemplateInput = {
  availableFontSizes: Array<Scalars['Float']['input']>
  availableFonts: Array<StudioFontInput>
  colors: Array<StudioColorInput>
  displayName: Scalars['String']['input']
  height: Scalars['Float']['input']
  id: Scalars['ID']['input']
  imageElements: Array<StudioImageElementInput>
  orientation: StudioOrientation
  placeholderTextElements: Array<StudioPlaceholderTextElementInput>
  plainTextElements: Array<StudioPlainTextElementInput>
  rectangleElements: Array<StudioRectangleElementInput>
  renderMethod?: InputMaybe<StudioRenderMethod>
  staticTextElements: Array<StudioStaticTextElementInput>
  styledTextElements: Array<StudioStyledTextElementInput>
  version: Scalars['String']['input']
  width: Scalars['Float']['input']
  xBleed?: InputMaybe<Scalars['Float']['input']>
  yBleed?: InputMaybe<Scalars['Float']['input']>
}

export type StudioUpdateTemplateRequest = {
  createdBy: Scalars['String']['input']
  template: StudioUpdateTemplateInput
}

export type StudioUpdateTemplateResult =
  | StudioDuplicateNameError
  | StudioTemplateValidationErrorResult
  | StudioUpdatedTemplate
  | StudioUpdatedTemplateError

export type StudioUpdatedTemplate = {
  __typename: 'StudioUpdatedTemplate'
  id: Scalars['String']['output']
  signedImageUrls: Array<StudioElementSignedUrl>
  signedMaskUrls: Array<StudioElementSignedUrl>
}

export type StudioUpdatedTemplateError = {
  __typename: 'StudioUpdatedTemplateError'
  message: Scalars['String']['output']
}

export type StudioUploadFontResult = StudioFontUpload | StudioFontUploadError

export type StudioVersionMismatchError = {
  __typename: 'StudioVersionMismatchError'
  message: Scalars['String']['output']
}

export enum StudioVerticalAlignment {
  BOTTOM = 'BOTTOM',
  MIDDLE = 'MIDDLE',
  TOP = 'TOP',
}
