import { gql } from '@apollo/client'

export const GET_ROOT_GROUPS_QUERY = gql`
  query GetRootGroupsQuery {
    studioRootGroups {
      ... on StudioGroup {
        id
        name
        path
        parentPath
        children {
          ... on StudioGroup {
            id
            name
            path
            parentPath
          }
          ... on StudioGroupTemplate {
            id
            name
            path
            parentPath
            previewUrl
          }
        }
      }
      ... on StudioGroupError {
        message
      }
    }
  }
`

export const GET_FONTS = gql`
  query GetFontsQuery {
    studioFonts {
      ... on StudioFont {
        id
        displayName
        urls {
          source
        }
      }
      ... on StudioFontError {
        message
      }
    }
  }
`

export const GET_GROUP_BY_PATH = gql`
  query GetGroupByPathQuery($path: String!) {
    studioGroupByPath(path: $path) {
      ... on StudioGroup {
        id
        name
        path
        parentPath
        children {
          ... on StudioGroup {
            id
            name
            path
            parentPath
          }
          ... on StudioGroupTemplate {
            id
            name
            path
            parentPath
            previewUrl
            lastUpdated
            updatedBy
            templateStatus
          }
        }
      }
      ... on StudioGroupError {
        message
      }
    }
  }
`

export const GET_TEMPLATE_QUERY = gql`
  query GetTemplateQuery($id: ID!) {
    studioTemplateById(id: $id) {
      ... on StudioTemplate {
        __typename
        id
        status
        version
        displayName
        orientation
        width(decimalPlaces: 2)
        height(decimalPlaces: 2)
        xBleed
        yBleed
        renderMethod
        groupMapping {
          path
          parentPath
        }
        availableFonts {
          id
          displayName
          urls {
            source
          }
        }
        availableFontSizes
        colors {
          name
          hex
        }
        elements {
          ... on StudioRectangleElement {
            __typename
            name
            x(decimalPlaces: 2)
            y(decimalPlaces: 2)
            width(decimalPlaces: 2)
            height(decimalPlaces: 2)
            opacity
            rotation(decimalPlaces: 2)
            visible
            fill {
              color {
                name
                hex
              }
            }
            outline {
              width
              color {
                name
                hex
              }
            }
          }
          ... on StudioImageElement {
            __typename
            name
            x(decimalPlaces: 2)
            y(decimalPlaces: 2)
            width(decimalPlaces: 2)
            height(decimalPlaces: 2)
            editable
            required
            visible
            opacity
            rotation(decimalPlaces: 2)
            mask {
              __typename
              key
              url
              contentType
              status
            }
            image {
              __typename
              key
              url
              contentType
              status
            }
            isHandwriting
          }
          ... on StudioTextElement {
            __typename
            name
            x(decimalPlaces: 2)
            y(decimalPlaces: 2)
            width(decimalPlaces: 2)
            height(decimalPlaces: 2)
            opacity
            horizontalAlignment
            verticalAlignment
            lineSpacing
            rotation(decimalPlaces: 2)
            visible
            isHandwriting
            font {
              ... on StudioFont {
                id
                displayName
                urls {
                  source
                }
              }
              ... on StudioFallbackFont {
                id
                displayName
                originalFont
                urls {
                  source
                }
              }
            }
            fontSize
            color {
              name
              hex
            }
            shadow {
              color {
                name
                hex
              }
              offsetX
              offsetY
            }
            text {
              ... on StudioStaticTextPart {
                text
                textIntent {
                  intent
                  format
                }
              }
              ... on StudioPlainTextPart {
                textTransform
                text
                allowBlank
                allowDefault
                customNo
                maxCharacters
                textIntent {
                  intent
                  format
                }
              }
              ... on StudioStyledTextPart {
                text
                allowBlank
                allowDefault
                textIntent {
                  intent
                  format
                }
              }
              ... on StudioPlaceholderTextPart {
                textParts {
                  ... on StudioStaticTextPart {
                    text
                    textIntent {
                      format
                      intent
                    }
                  }
                  ... on StudioPlainTextPart {
                    textTransform
                    text
                    textIntent {
                      format
                      intent
                    }
                    allowBlank
                    allowDefault
                    customNo
                    maxCharacters
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_GROUP_TEMPLATE_BY_ID_QUERY = gql`
  query GetGroupTemplateQuery($id: ID!) {
    studioTemplateById(id: $id) {
      ... on StudioTemplate {
        __typename
        id
        groupMapping {
          id
          name
          path
          parentPath
          previewUrl
          lastUpdated
          updatedBy
        }
      }
    }
  }
`

export const GET_BY_NAME = gql`
  query GetByNameQuery($name: String!) {
    searchByName(name: $name) {
      ... on StudioGroup {
        id
        name
        path
        parentPath
        children {
          ... on StudioGroupTemplate {
            id
            name
            previewUrl
            path
            parentPath
          }
          ... on StudioGroup {
            id
            name
            path
            parentPath
          }
        }
      }
    }
  }
`

export const GET_IMPORT_STATUS_BY_ID = gql`
  query TemplateImportStatusById($templateId: String!) {
    templateImportStatusById(templateId: $templateId) {
      ... on StudioTemplateImportStatus {
        status
        templateId
        templateName
        groupPath
        created
        updated
        updatedBy
        errors {
          code
          message
        }
      }
      ... on StudioImportError {
        message
      }
    }
  }
`

export const GET_TEMPLATE_GROUPS_BY_ID = gql`
  query GetTemplateGroups($ids: [ID!]!) {
    studioTemplateGroupsByIds(ids: $ids) {
      id
      name
      path
      parentPath
      lastUpdated
      updatedBy
      previewUrl
    }
  }
`

export const GET_TEMPLATE_STATUS_BY_ID = gql`
  query TemplateStatusById($id: ID!) {
    studioTemplateById(id: $id) {
      ... on StudioTemplate {
        status
      }
    }
  }
`
export const GET_BY_PATH_QUERY = gql`
  query GetPathQuery($term: String!) {
    searchByPath(term: $term) {
      ... on StudioGroup {
        id
        name
        path
        parentPath
      }
      ... on StudioGroupTemplate {
        id
        name
        previewUrl
        previewStatus
        path
        parentPath
        lastUpdated
        updatedBy
      }
      ... on StudioNotFound {
        message
      }
      ... on StudioError {
        message
      }
    }
  }
`

export const SEARCH_GROUPS_BY_PATH_SUBSTRING = gql`
  query SearchGroupsByPathSubstring($path: String!) {
    studioGroupsByPathSubstring(path: $path) {
      ... on StudioGroupSearch {
        id
        name
        path
      }

      ... on StudioGroupError {
        message
      }
    }
  }
`

export const GET_NODE_META_DATA = gql`
  query GetNodeMetaData($input: StudioNodeMetaDataInput!) {
    studioNodeMetaData(input: $input) {
      ... on StudioNodeMetaData {
        id
        type
        path
        actions {
          canDeleteNode
        }
        totals {
          groups
          templates
        }
      }
      ... on StudioError {
        message
      }
    }
  }
`
export const GET_INTENTS = gql`
  query GetIntentsQuery {
    studioIntents {
      key
      value
      disabled
      formats {
        key
        value
      }
    }
  }
`
