/* istanbul ignore file */
import React, { FC, useCallback, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import {
  Route,
  useLocation,
  useNavigate,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { StudioPage } from './StudioPage'
import { ErrorScreen } from './components/ErrorScreen'
import { ErrorBoundary } from './ErrorBoundary'
import {
  GRAPHQL_GATEWAY_URL,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
  OKTA_REDIRECT_URI,
} from './constants'
import { RendererProviderDefault } from '@moonpig/renderer-react-provider-default'
import { getQueryStringToggles } from './utils/featureToggles'
import CustomLoginCallback from './components/CustomLoginCallback'
import { encodePathArray } from './utils/path'
import { useOktaAuth } from '@okta/okta-react'

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER as string,
  clientId: OKTA_CLIENT_ID,
  redirectUri: OKTA_REDIRECT_URI,
})

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_ENABLE_XSTATE_DEBUGGING === 'true'
) {
  require('@xstate/inspect').inspect({
    iframe: false,
  })
}

const authLink = setContext((_, { headers }) => {
  const token = oktaAuth.getAccessToken()
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }
})

const httpLink = createHttpLink({
  uri: GRAPHQL_GATEWAY_URL,
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

type TemplatePathStructure = {
  templateId: string
  path: string
}

const getPathStructure = (
  pathname: string,
  hash: string,
): TemplatePathStructure => {
  const pathArray = `${pathname}${decodeURI(hash)}`.replace('/', '').split('/')
  let templateId = ''
  let logicalPath = ''
  if (pathArray.length > 0) {
    if (
      /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/.test(
        pathArray[pathArray.length - 1]!,
      )
    ) {
      templateId = pathArray.pop() || ''
    } else {
      templateId = ''
    }
    logicalPath = encodePathArray(pathArray)
  }
  return {
    templateId,
    path: logicalPath,
  }
}

const StudioPageProvider: FC = () => {
  const location = useLocation()

  const toggles = getQueryStringToggles(location.search)

  const templatePathStructure = getPathStructure(
    location.pathname,
    location.hash,
  )

  return (
    <ApolloProvider client={client}>
      <RendererProviderDefault>
        <StudioPage
          toggles={toggles}
          templateId={templatePathStructure.templateId}
          logicalPath={templatePathStructure.path}
        />
      </RendererProviderDefault>
    </ApolloProvider>
  )
}

const NotFound: FC = () => {
  return (
    <ErrorScreen
      title={'Not found'}
      description={'The requested group or template is not found :('}
    />
  )
}

const RequiresAuthentication: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth()

  useEffect(() => {
    if (!authState) {
      return
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      )
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect()
    }
  }, [oktaAuth, authState?.isAuthenticated, authState])

  if (!authState || !authState?.isAuthenticated) {
    return <h3>Loading...</h3>
  }

  return <Outlet />
}

const Root: FC = () => {
  const navigate = useNavigate()

  const restoreOriginalUri = useCallback(
    async (_: any, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
        replace: true,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Outlet />
    </Security>
  )
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route element={<RequiresAuthentication />}>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/*" element={<StudioPageProvider />} />
      </Route>

      <Route path="/login/callback" element={<CustomLoginCallback />} />
    </Route>,
  ),
)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>,
)
