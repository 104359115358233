import { RenderMethod, Scene, SceneObject } from '@moonpig/renderer-scene-types'
import {
  StudioPlaceholderTextPart,
  StudioPlainTextPart,
  StudioStaticTextPart,
  StudioStyledTextPart,
  StudioTemplate,
  StudioTextElement,
} from '../../types'
import { StudioRenderMethod } from '../../__graphql__/types'

const textTypeMap = {
  StudioStaticTextPart: (element: StudioTextElement): string => {
    const textPart = element.text as StudioStaticTextPart
    return textPart.text
  },
  StudioPlainTextPart: (element: StudioTextElement): string => {
    const textPart = element.text as StudioPlainTextPart
    return textPart.textTransform === 'UPPERCASE'
      ? textPart.text.toUpperCase()
      : textPart.text
  },
  StudioStyledTextPart: (element: StudioTextElement): string => {
    const textPart = element.text as StudioStyledTextPart
    return textPart.text
  },
  StudioPlaceholderTextPart: (element: StudioTextElement): string => {
    const textPart = element.text as StudioPlaceholderTextPart
    return textPart.textParts
      .map<string>(textPart => {
        if (textPart.__typename === 'StudioStaticTextPart') {
          return textPart.text
        }

        return textPart.textTransform === 'UPPERCASE'
          ? textPart.text.toUpperCase()
          : textPart.text
      })
      .join('')
  },
}

const renderMethodMap: { [renderMethod in StudioRenderMethod]: RenderMethod } =
  {
    GDI: 'LEGACY_1',
    WPF: 'LEGACY_2',
  }

export const buildRendererScene = (template: StudioTemplate): Scene => {
  const xBleedOffset = template.xBleed || 0
  const yBleedOffset = template.yBleed || 0

  return {
    width: template.width,
    height: template.height,
    objects: template.elements.reduce<SceneObject[]>((acc, element, index) => {
      switch (element.__typename) {
        case 'StudioImageElement': {
          acc.push({
            type: 'IMAGE',
            id: `${index}`,
            frame: {
              x: element.x + xBleedOffset,
              y: element.y + yBleedOffset,
              width: element.width,
              height: element.height,
              rotation: element.rotation,
            },
            opacity: element.opacity,
            url: element.image.url,
            maskUrl: element.mask?.url,
          })
          break
        }
        case 'StudioTextElement': {
          acc.push({
            type: 'TEXT',
            id: `${index}`,
            frame: {
              x: element.x + xBleedOffset,
              y: element.y + yBleedOffset,
              width: element.width,
              height: element.height,
              rotation: element.rotation,
            },
            opacity: element.opacity,
            renderMethod: template.renderMethod
              ? renderMethodMap[template.renderMethod]
              : 'LEGACY_1',
            color: element.color.hex,
            fontUrl: element.font.urls.source!,
            maxFontSize: element.fontSize,
            horizontalAlignment: element.horizontalAlignment,
            verticalAlignment: element.verticalAlignment,
            lineSpacing: element.lineSpacing,
            shadow: element.shadow
              ? {
                  offsetX: element.shadow.offsetX,
                  offsetY: element.shadow.offsetY,
                  color: element.shadow.color.hex,
                }
              : null,
            text: textTypeMap[element.text.__typename](element),
            fontMetrics: null,
          })
          break
        }
        case 'StudioRectangleElement': {
          acc.push({
            type: 'RECTANGLE',
            id: `${index}`,
            fillColor: '#ffffff',
            outline: null,
            frame: {
              x: element.x + xBleedOffset,
              y: element.y + yBleedOffset,
              width: element.width,
              height: element.height,
              rotation: element.rotation,
            },
            opacity: element.opacity,
          })
        }
      }

      return acc
    }, []),
  }
}
