import { StudioAssetStatus } from '../../__graphql__/types'
import { UNSET_ASSET_KEY } from '../../constants'
import { StudioImageElement, StudioTemplate } from '../../types'

export const buildImageElement = (
  overrides: Partial<StudioImageElement & { hasError: boolean }>,
  template: StudioTemplate,
): StudioImageElement => {
  const nextIndex =
    template.elements.filter(
      ({ __typename }) => __typename === 'StudioImageElement',
    ).length + 1

  const width = overrides.width ?? 0
  const height = overrides.height ?? 0

  const x = template!.width / 2 - width / 2
  const y = template!.height / 2 - height / 2

  return {
    __typename: 'StudioImageElement',
    name: `Image ${nextIndex}`,
    width,
    height,
    x,
    y,
    rotation: 0,
    opacity: 1,
    mask: null,
    image: {
      url: '',
      status: StudioAssetStatus.PENDING,
      key: UNSET_ASSET_KEY,
      contentType: '',
      __typename: 'StudioAsset',
    },
    editable: false,
    visible: true,
    required: false,
    isHandwriting: false,
    hasError: false,
    ...overrides,
  }
}
