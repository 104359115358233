import {
  IconSystemImageUpload,
  IconSystemTextField,
} from '@moonpig/launchpad-assets'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import { colorValue } from '@moonpig/launchpad-theme'
import {
  isImageElement,
  isPlaceholderTextPart,
  isTextElement,
} from '../../utils'
import { FC } from 'react'
import { useElementData } from '../../data'
import { useMachine } from '../../state/xstate'
import { IconSystemPlaceholderTextField } from '../ElementSelectionControls/img/IconSystemPlaceholderTextField'

const ICON_SIZE = 32
const ICON_PADDING = 8
const BORDER_WIDTH = 3

const StyledReviewFrame = styled.div`
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  display: block;
  width: 100%;
  height: 100%;
`

const StyledBackground = styled.button`
  background-color: rgba(255, 255, 255, 0.5);
  --display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`

const StyledIconBackground = styled.div`
  height: ${ICON_SIZE + ICON_PADDING * 2 + BORDER_WIDTH * 2}px;
  width: ${ICON_SIZE + ICON_PADDING * 2 + BORDER_WIDTH * 2}px;
  padding: ${ICON_PADDING}px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  border: ${BORDER_WIDTH}px solid ${colorValue('colorBorder01')};  ')};
`

export const ReviewImageIcon: FC = () => {
  const theme = useTheme()
  const { colorBlack100 } = theme.palette
  const halfIconSize = ICON_SIZE * 0.5
  return (
    <IconSystemImageUpload
      x={-halfIconSize}
      y={-halfIconSize}
      width={ICON_SIZE}
      height={ICON_SIZE}
      color={colorBlack100}
      display={'block'}
      aria-label="Editable Image"
    />
  )
}

const ReviewTextIcon: FC = () => {
  const theme = useTheme()
  const { colorBlack100 } = theme.palette
  const halfIconSize = ICON_SIZE * 0.5
  return (
    <IconSystemTextField
      x={-halfIconSize}
      y={-halfIconSize}
      width={ICON_SIZE}
      height={ICON_SIZE}
      color={colorBlack100}
      display={'block'}
      aria-label="Editable Text"
    />
  )
}

const ReviewPlaceHolderTextIcon: FC = () => {
  const theme = useTheme()
  const { colorBlack100 } = theme.palette
  const halfIconSize = ICON_SIZE * 0.5
  return (
    <IconSystemPlaceholderTextField
      x={-halfIconSize}
      y={-halfIconSize}
      width={ICON_SIZE}
      height={ICON_SIZE}
      color={colorBlack100}
      display={'block'}
      aria-label="Placeholder Editable Text"
    />
  )
}

export const ReviewOverlay: FC<{
  id: string
  x: number
  y: number
  width: number
  height: number
}> = ({ id }) => {
  const { send } = useMachine()
  const { elementData } = useElementData(id)
  const isEditableTextElement =
    isTextElement(elementData) &&
    elementData.text.__typename !== 'StudioStaticTextPart'
  const isEditableImageElement =
    isImageElement(elementData) && elementData.editable
  return (
    <>
      {elementData && (
        <StyledReviewFrame
          data-testid={`review-overlay-${elementData.name}`}
          aria-label="something"
          style={{
            cursor: 'pointer',
            pointerEvents: 'all',
            position: 'absolute',
            margin: '0',
            padding: '0',
          }}
        >
          {isEditableTextElement && (
            <StyledBackground>
              <StyledIconBackground
                onClick={() => send({ type: 'SELECT_ELEMENT', id })}
              >
                {isPlaceholderTextPart(elementData.text) ? (
                  <ReviewPlaceHolderTextIcon />
                ) : (
                  <ReviewTextIcon />
                )}
              </StyledIconBackground>
            </StyledBackground>
          )}
          {isEditableImageElement && (
            <StyledBackground>
              <StyledIconBackground
                onClick={() => send({ type: 'SELECT_ELEMENT', id })}
              >
                <ReviewImageIcon />
              </StyledIconBackground>
            </StyledBackground>
          )}
        </StyledReviewFrame>
      )}
    </>
  )
}
