import {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Flex } from '@moonpig/launchpad-components'
import { StudioGroup, StudioGroupTemplate, StudioNode } from '../../../types'
import { ListView } from './ListView/ListView'
import { GridView } from './GridView/GridView'
import { useImportMachine } from '../UploadMachineProvider'
import { NewUploadEvent } from '../../../machines/templates/import-machine'
import { useTemplateExplorer } from '../TemplateExplorerProvider'
import { colorValue } from '@moonpig/launchpad-theme-mission-control'
import { AlphabetList } from './AlphabetList/AlphabetList'
import {
  nodesToSectionsByAlphabet,
  NUMBER_OF_ITEMS_ENABLING_ALPHABET,
  Section,
} from './AlphabetList/AlphabetListHelpers'
import { NoResults } from './Content/NoResults'
import { StudioTemplateStatus } from '../../../__graphql__/types'

export const StyledDialogContent = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  max-height: calc(100% - 135px);
  position: relative;
`

export const StyledTemplateContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  flex: 1 1 auto;
  padding-bottom: 20px;
`

export const StyledTemplateContent = styled.nav`
  overflow: scroll;
  height: 100%;
`

type GroupedChildren = {
  importingTemplates: StudioGroupTemplate[]
  otherChildren: StudioNode[]
}

const sortChildren = (
  children: StudioNode[] | undefined,
  sortBy: 'ASC' | 'DESC',
): StudioNode[] => {
  if (!children) {
    return []
  }

  const sortFunc = (sortBy: 'ASC' | 'DESC') => (a: StudioNode, b: StudioNode) =>
    sortBy === 'ASC'
      ? a.name.localeCompare(b.name)
      : b.name.localeCompare(a.name)

  const { importingTemplates, otherChildren }: GroupedChildren =
    children.reduce(
      (
        { importingTemplates, otherChildren }: GroupedChildren,
        currentNode: StudioNode,
      ) => {
        if (
          currentNode.__typename === 'StudioGroupTemplate' &&
          currentNode.templateStatus === StudioTemplateStatus.IMPORTING
        ) {
          return {
            importingTemplates: [...importingTemplates, currentNode],
            otherChildren: [...otherChildren],
          }
        }
        return {
          importingTemplates: [...importingTemplates],
          otherChildren: [...otherChildren, currentNode],
        }
      },
      { importingTemplates: [], otherChildren: [] },
    )

  return [...importingTemplates, ...otherChildren.sort(sortFunc(sortBy))]
}

type ContentProps = {
  group: StudioGroup
  setSelectedGroup: (group: StudioGroup, newWindow: boolean) => void
  onTemplateSelect: (node: StudioNode, newWindow: boolean) => void
  onMoved: () => void
}

export const Content: FC<PropsWithChildren<ContentProps>> = ({
  group,
  setSelectedGroup,
  onTemplateSelect,
  onMoved,
}) => {
  const templateExplorer = useTemplateExplorer()
  const scrollableList = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (scrollableList.current === null) {
      return
    }

    scrollableList.current!.scrollTop = 0
  }, [group])

  const sortedChildren = useMemo(
    () => sortChildren(group.children, templateExplorer.state.sortBy),
    [group.children, templateExplorer.state.sortBy],
  )

  let sections: Section[]
  const showSections =
    sortedChildren.length > NUMBER_OF_ITEMS_ENABLING_ALPHABET - 1
  if (showSections) {
    sections = nodesToSectionsByAlphabet(
      sortedChildren,
      templateExplorer.state.sortBy,
    )
  } else {
    sections = [
      {
        title: '',
        data: sortedChildren,
      },
    ]
  }

  return (
    <DropContainer data-testid={'dropContainer'}>
      {showSections && (
        <AlphabetList
          nodes={sortedChildren}
          scrollableList={scrollableList}
        ></AlphabetList>
      )}
      <StyledTemplateContentContainer>
        {sortedChildren.length > 0 && (
          <StyledTemplateContent ref={scrollableList}>
            <>
              {templateExplorer.state.viewBy === 'GRID' && (
                <GridView
                  sections={sections}
                  setSelectedGroup={setSelectedGroup}
                  onTemplateSelect={onTemplateSelect}
                  onMoved={onMoved}
                />
              )}
              {templateExplorer.state.viewBy === 'LIST' && (
                <ListView
                  sections={sections}
                  setSelectedGroup={setSelectedGroup}
                  onTemplateSelect={onTemplateSelect}
                  onMoved={onMoved}
                />
              )}
            </>
          </StyledTemplateContent>
        )}
        {sortedChildren.length === 0 && <NoResults />}
      </StyledTemplateContentContainer>
    </DropContainer>
  )
}

const DropContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { importMachineService } = useImportMachine()
  const templateExplorer = useTemplateExplorer()

  const [dragDepth, setDragDepth] = useState<number>(0)
  const [isDragging, setIsDragging] = useState<boolean>(false)

  const onDropHandler = (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    setDragDepth(0)
    setIsDragging(false)

    const files = event.dataTransfer.files

    if (files && files.length > 0) {
      const events: NewUploadEvent[] = Array.from(files).map(file => {
        return {
          type: 'NEW_UPLOAD',
          file,
          currentGroup: templateExplorer.state.currentGroup,
        }
      })

      importMachineService.send(events)
    }
  }

  const onDragOverHandler = (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onDragEnterHandler = (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    setDragDepth(dragDepth + 1)
    setIsDragging(true)
  }

  const onDragLeaveHandler = (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    setDragDepth(dragDepth - 1)

    if (dragDepth <= 1) {
      setIsDragging(false)
    }
  }

  return (
    <StyledDialogContent
      data-testid="content-container"
      onDrop={onDropHandler}
      onDragOver={onDragOverHandler}
      onDragEnter={onDragEnterHandler}
      onDragLeave={onDragLeaveHandler}
    >
      {isDragging && <Overlay />}
      {children}
    </StyledDialogContent>
  )
}

const StyledOverlayContainer = styled.div`
  position: absolute;
  height: 100%;
  inset: 0;
`

const StyledOverlayBackground = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${colorValue('colorBorder01')};
  opacity: 0.2;
`

const StyledOverlayContent = styled(Flex)`
  justify-items: center;
  justify-content: center;
  margin: auto;
  font-weight: 400;
  font-size: 16px;
  color: ${colorValue('colorBorder01')};
`

const Overlay = () => {
  return (
    <StyledOverlayContainer>
      <StyledOverlayBackground />
      <StyledOverlayContent zIndex={99}>
        Drop Files To Upload
      </StyledOverlayContent>
    </StyledOverlayContainer>
  )
}
